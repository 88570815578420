import React from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";
import Header from "../components/Header";
import "../styles/TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <Layout>
      <SEO path="/terms-and-conditions/" />
      <Header title="Terms and Conditions" />
      <p className="TermsAndConditions--emphasis">Last updated June 10, 2020</p>
      <div>
        <h3>TABLE OF CONTENTS</h3>
        <ul className="TermsAndConditions__table-of-contents">
          <li>
            <a href="#1-agreement-to-terms">1. AGREEMENT TO TERMS</a>
          </li>
          <li>
            <a href="#2-accetable-use">2. ACCEPTABLE USE</a>
          </li>
          <li>
            <a href="#3-information-you-provide-to-us">
              3. INFORMATION YOU PROVIDE TO US
            </a>
          </li>
          <li>
            <a href="#4-our-content">4. OUR CONTENT</a>
          </li>
          <li>
            <a href="#5-link-to-third-party-content">
              5. LINK TO THIRD PARTY CONTENT
            </a>
          </li>
          <li>
            <a href="#6-site-management">6. SITE MANAGEMENT</a>
          </li>
          <li>
            <a href="#7-modification-to-and-availability-of-the-site">
              7. MODIFICATION TO AND AVAILABILITY OF THE SITE
            </a>
          </li>
          <li>
            <a href="#8-disclaimer-limitation-of-liability">
              8. DISCLAIMER/LIMITATION OF LIABILITY
            </a>
          </li>
          <li>
            <a href="#9-term-and-termination">9. TERM AND TERMINATION</a>
          </li>
          <li>
            <a href="#10-general">10. GENERAL</a>
          </li>
        </ul>
        <h3 id="1-agreement-to-terms">1. AGREEMENT TO TERMS</h3>
        <p>
          1.1 These Terms and Conditions constitute a legally binding agreement
          made between you, whether personally or on behalf of an entity (you),
          and Andrico Karoulla, located at 317 Brockley Road, SE4 2QZ, London,
          __________ United Kingdom (we, us), concerning your access to and use
          of the Cali Skills (https://calisthenicsskills.com) website as
          well as any related applications (the Site).
          <br />
          The Site provides the following services: A bodyweight fitness
          progress tracking application (Services). You agree that by accessing
          the Site and/or Services, you have read, understood, and agree to be
          bound by all of these Terms and Conditions. If you do not agree with
          all of these Terms and Conditions, then you are prohibited from using
          the Site and Services and you must discontinue use immediately. We
          recommend that you print a copy of these Terms and Conditions for
          future reference.
        </p>
        <p>
          1.2 The supplemental policies set out in Section 1.7 below, as well as
          any supplemental terms and condition or documents that may be posted
          on the Site from time to time, are expressly incorporated by
          reference.
        </p>
        <p>
          1.3 We may make changes to these Terms and Conditions at any time. The
          updated version of these Terms and Conditions will be indicated by an
          updated “Revised” date and the updated version will be effective as
          soon as it is accessible. You are responsible for reviewing these
          Terms and Conditions to stay informed of updates. Your continued use
          of the Site represents that you have accepted such changes.
        </p>
        <p>
          1.4 We may update or change the Site from time to time to reflect
          changes to our products, our users' needs and/or our business
          priorities.
        </p>
        <p>
          1.5 Our site is directed to people residing in United Kingdom. The
          information provided on the Site is not intended for distribution to
          or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country.
        </p>
        <p>
          1.6 The Site is intended for users who are at least 18 years old. If
          you are under the age of 18, you are not permitted to register for the
          Site or use the Services without parental permission.
        </p>
        <p>
          1.7 Additional policies which also apply to your use of the Site
          include: Our Privacy Notice
          https://calisthenicsskills.com/privacy-policy, which sets out the
          terms on which we process any personal data we collect from you, or
          that you provide to us. By using the Site, you consent to such
          processing and you warrant that all data provided by you is accurate.
        </p>
        <h3 id="2-accetable-use">2. ACCEPTABLE USE</h3>
        <p>
          2.1 You may not access or use the Site for any purpose other than that
          for which we make the site and our services available. The Site may
          not be used in connection with any commercial endeavors except those
          that are specifically endorsed or approved by us.
        </p>
        <p>2.2 As a user of this Site, you agree not to:</p>
        <ul className="TermsAndConditions__list">
          <li>
            Systematically retrieve data or other content from the Site to a
            compile database or directory without written permission from us
          </li>
          <li>
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users to send unsolicited email
            or creating user accounts under false pretenses
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchases on the Site
          </li>
          <li> Use the Site to advertise or sell goods and services</li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any content or enforce limitations on the use
          </li>
          <li> Engage in unauthorized framing of or linking to the Site</li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords
          </li>
          <li>
            Make improper use of our support services, or submit false reports
            of abuse or misconduct
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks and services connected to the Site
          </li>
          <li>
            Attempt to impersonate another user or person, or use the username
            of another user
          </li>
          <li> Sell or otherwise transfer your profile</li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person
          </li>
          <li>
            Use the Site or our content as part of any effort to compete with us
            or to create a revenue-generating endeavor or commercial enterprise
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site
          </li>
          <li>
            Attempt to access any portions of the Site that you are restricted
            from accessing
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees, agents,
            or other users
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any of
            the content
          </li>
          <li>
            Copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material that interferes with any party’s
            uninterrupted use and enjoyment of the Site, or any material that
            acts as a passive or active information collection or transmission
            mechanism
          </li>
          <li>
            Use, launch, or engage in any automated use of the system, such as
            using scripts to send comments or messages, robots, scrapers,
            offline readers, or similar data gathering and extraction tools
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations
          </li>
          <li>
            Threaten users with negative feedback or offering services solely to
            give positive feedback to users
          </li>
          <li>Misrepresent experience, skills, or information about a User</li>
          <li> Advertise products or services not intended by us</li>
          <li>
            Falsely imply a relationship with us or another company with whom
            you do not have a relationship
          </li>
        </ul>
        <h3 id="3-information-you-provide-to-us">
          3. INFORMATION YOU PROVIDE TO US
        </h3>
        <p>
          3.1 You represent and warrant that: (a) all registration information
          you submit will be true, accurate, current, and complete and relate to
          you and not a third party; (b) you will maintain the accuracy of such
          information and promptly update such information as necessary; (c) you
          will keep your password confidential and will be responsible for all
          use of your password and account; (d) you have the legal capacity and
          you agree to comply with these Terms and Conditions; and (e) you are
          not a minor in the jurisdiction in which you reside, or if a minor,
          you have received parental permission to use the Site. If you know or
          suspect that anyone other than you knows your user information (such
          as an identification code or user name) and/or password you must
          promptly notify us at andrico@calisthenicsskills.com.
        </p>
        <p>
          3.2 If you provide any information that is untrue, inaccurate, not
          current or incomplete, we may suspend or terminate your account. We
          may remove or change a user name you select if we determine that such
          user name is inappropriate.
        </p>
        <h3 id="4-our-content">4. OUR CONTENT</h3>
        <p></p>
        <p>
          4.1 Unless otherwise indicated, the Site and Services including source
          code, databases, functionality, software, website designs, audio,
          video, text, photographs, and graphics on the Site (Our Content) are
          owned or licensed to us, and are protected by copyright and trade mark
          laws.
        </p>
        <p>
          4.2 Except as expressly provided in these Terms and Conditions, no
          part of the Site, Services or Our Content may be copied, reproduced,
          aggregated, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted, distributed, sold, licensed, or
          otherwise exploited for any commercial purpose whatsoever, without our
          express prior written permission.
        </p>
        <p>
          4.3 Provided that you are eligible to use the Site, you are granted a
          limited licence to access and use the Site and Our Content and to
          download or print a copy of any portion of the Content to which you
          have properly gained access solely for your personal, non-commercial
          use.
        </p>
        <p>
          4.4 You shall not (a) try to gain unauthorised access to the Site or
          any networks, servers or computer systems connected to the Site;
          and/or (b) make for any purpose including error correction, any
          modifications, adaptions, additions or enhancements to the Site or Our
          Content, including the modification of the paper or digital copies you
          may have downloaded.
        </p>
        <p>
          4.5 We shall (a) prepare the Site and Our Content with reasonable
          skill and care; and (b) use industry standard virus detection software
          to try to block the uploading of content to the Site that contains
          viruses.
        </p>
        <p>
          4.6 The content on the Site is provided for general information only.
          It is not intended to amount to advice on which you should rely. You
          must obtain professional or specialist advice before taking, or
          refraining from taking, any action on the basis of the content on the
          Site.
        </p>
        <p>
          4.7 Although we make reasonable efforts to update the information on
          our site, we make no representations, warranties or guarantees,
          whether express or implied, that Our Content on the Site is accurate,
          complete or up to date.
        </p>
        <h3 id="5-link-to-third-party-content">
          5. LINK TO THIRD PARTY CONTENT
        </h3>
        <p>
          5.1 The Site may contain links to websites or applications operated by
          third parties.We do not have any influence or control over any such
          third party websites or applications or the third party operator. We
          are not responsible for and do not endorse any third party websites or
          applications or their availability or content.
        </p>
        <p>
          5.2 We accept no responsibility for adverts contained within the Site.
          If you agree to purchase goods and/or services from any third party
          who advertises in the Site, you do so at your own risk. The
          advertiser, and not us, is responsible for such goods and/or services
          and if you have any questions or complaints in relation to them, you
          should contact the advertiser.
        </p>
        <h3 id="6-site-management">6. SITE MANAGEMENT</h3>
        <p>
          6.1 We reserve the right at our sole discretion, to (1) monitor the
          Site for breaches of these Terms and Conditions; (2) take appropriate
          legal action against anyone in breach of applicable laws or these
          Terms and Conditions; (3) remove from the Site or otherwise disable
          all files and content that are excessive in size or are in any way a
          burden to our systems; and (4) otherwise manage the Site in a manner
          designed to protect our rights and property and to facilitate the
          proper functioning of the Site and Services.
        </p>
        <p>
          6.2 We do not guarantee that the Site will be secure or free from bugs
          or viruses.
        </p>
        <p>
          6.3 You are responsible for configuring your information technology,
          computer programs and platform to access the Site and you should use
          your own virus protection software.
        </p>
        <h3 id="7-modification-to-and-availability-of-the-site">
          7. MODIFICATION TO AND AVAILABILITY OF THE SITE
        </h3>
        <p>
          7.1 We reserve the right to change, modify, or remove the contents of
          the Site at any time or for any reason at our sole discretion without
          notice. We also reserve the right to modify or discontinue all or part
          of the Services without notice at any time.
        </p>
        <p>
          7.2 We cannot guarantee the Site and Services will be available at all
          times. We may experience hardware, software, or other problems or need
          to perform maintenance related to the Site, resulting in
          interruptions, delays, or errors. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site or Services during any downtime or
          discontinuance of the Site or Services.We are not obliged to maintain
          and support the Site or Services or to supply any corrections,
          updates, or releases.
        </p>
        <p>
          7.3 There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Services,
          including descriptions, pricing, availability, and various other
          information. We reserve the right to correct any errors, inaccuracies,
          or omissions and to change or update the information at any time,
          without prior notice.
        </p>
        <h3 id="8-disclaimer-limitation-of-liability">
          8. DISCLAIMER/LIMITATION OF LIABILITY
        </h3>
        <p>
          8.1 The Site and Services are provided on an as-is and as-available
          basis. You agree that your use of the Site and/or Services will be at
          your sole risk except as expressly set out in these Terms and
          Conditions. All warranties, terms, conditions and undertakings,
          express or implied (including by statute, custom or usage, a course of
          dealing, or common law) in connection with the Site and Services and
          your use thereof including, without limitation, the implied warranties
          of satisfactory quality, fitness for a particular purpose and
          non-infringement are excluded to the fullest extent permitted by
          applicable law. We make no warranties or representations about the
          accuracy or completeness of the Site’s content and are not liable for
          any (1) errors or omissions in content: (2) any unauthorized access to
          or use of our servers and/or any and all personal information and/or
          financial information stored on our server; (3) any interruption or
          cessation of transmission to or from the site or services; and/or (4)
          any bugs, viruses, trojan horses, or the like which may be transmitted
          to or through the site by any third party. We will not be responsible
          for any delay or failure to comply with our obligations under these
          Terms and Conditions if such delay or failure is caused by an event
          beyond our reasonable control.
        </p>
        <p>
          8.2 Our responsibility for loss or damage suffered by you: Whether you
          are a consumer or a business user:
        </p>
        <ul className="TermsAndConditions__list">
          <li>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors and for fraud or fraudulent
            misrepresentation.
          </li>
          <li>
            If we fail to comply with these Terms and Conditions, we will be
            responsible for loss or damage you suffer that is a foreseeable
            result of our breach of these Terms and Conditions, but we would not
            be responsible for any loss or damage that were not foreseeable at
            the time you started using the Site/Services.
          </li>
        </ul>
        <p>
          Notwithstanding anything to the contrary contained in the
          Disclaimer/Limitation of Liability section, our liability to you for
          any cause whatsoever and regardless of the form of the action, will at
          all times be limited to a total aggregate amount equal to the greater
          of (a) the sum of £5000 or (b) the amount paid, if any, by you to us
          for the Services/Site during the six (6) month period prior to any
          cause of action arising. If you are a consumer user:
        </p>
        <ul className="TermsAndConditions__list">
          <li>
            Please note that we only provide our Site for domestic and private
            use. You agree not to use our Site for any commercial or business
            purposes, and we have no liability to you for any loss of profit,
            loss of business, business interruption, or loss of business
            opportunity.
          </li>
          <li>
            If defective digital content that we have supplied, damages a device
            or digital content belonging to you and this is caused by our
            failure to use reasonable care and skill, we will either repair the
            damage or pay you compensation.
          </li>
          <li>
            You have legal rights in relation to goods that are faulty or not as
            described. Advice about your legal rights is available from your
            local Citizens' Advice Bureau or Trading Standards office. Nothing
            in these Terms and Conditions will affect these legal rights.
          </li>
        </ul>
        <h3 id="9-term-and-termination">9. TERM AND TERMINATION</h3>
        <p>
          9.1 These Terms and Conditions shall remain in full force and effect
          while you use the Site or Services or are otherwise a user of the
          Site, as applicable. You may terminate your use or participation at
          any time, for any reason, by following the instructions for
          terminating user accounts in your account settings, if available, or
          by contacting us at karoulla.andrico@gmail.com.
        </p>
        <p>
          9.2 Without limiting any other provision of these Terms and
          Conditions, we reserve the right to, in our sole discretion and
          without notice or liability, deny access to and use of the Site and
          the Services (including blocking certain IP addresses), to any person
          for any reason including without limitation for breach of any
          representation, warranty or covenant contained in these Terms and
          Conditions or of any applicable law or regulation.
        </p>
        <p>
          If we determine, in our sole discretion, that your use of the
          Site/Services is in breach of these Terms and Conditions or of any
          applicable law or regulation, we may terminate your use or
          participation in the Site and the Services or delete your profile and
          any content or information that you posted at any time, without
          warning, in our sole discretion.
        </p>
        <p>
          9.3 If we terminate or suspend your account for any reason set out in
          this Section 9, you are prohibited from registering and creating a new
          account under your name, a fake or borrowed name, or the name of any
          third party, even if you may be acting on behalf of the third party.
          In addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </p>
        <h3 id="10-general">10. GENERAL</h3>
        <p>
          10.1 Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. You hereby agree to
          the use of electronic signatures, contracts, orders and other records
          and to electronic delivery of notices, policies and records of
          transactions initiated or completed by us or via the Site. You hereby
          waive any rights or requirements under any statutes, regulations,
          rules, ordinances or other laws in any jurisdiction which require an
          original signature or delivery or retention of non-electronic records,
          or to payments or the granting of credits by other than electronic
          means.
        </p>
        <p>
          10.2 These Terms and Conditions and any policies or operating rules
          posted by us on the Site or in respect to the Services constitute the
          entire agreement and understanding between you and us.
        </p>
        <p>
          10.3 Our failure to exercise or enforce any right or provision of
          these Terms and Conditions shall not operate as a waiver of such right
          or provision.
        </p>
        <p>
          10.4 We may assign any or all of our rights and obligations to others
          at any time.
        </p>
        <p>
          10.5 We shall not be responsible or liable for any loss, damage, delay
          or failure to act caused by any cause beyond our reasonable control.
        </p>
        <p>
          10.6 If any provision or part of a provision of these Terms and
          Conditions is unlawful, void or unenforceable, that provision or part
          of the provision is deemed severable from these Terms and Conditions
          and does not affect the validity and enforceability of any remaining
          provisions.
        </p>
        <p>
          10.7 There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms and
          Conditions or use of the Site or Services.
        </p>
        <p>
          10.8 For consumers only - Please note that these Terms and Conditions,
          their subject matter and their formation, are governed by English law.
          You and we both agree that the courts of England and Wales will have
          exclusive jurisdiction expect that if you are a resident of Northern
          Ireland you may also bring proceedings in Northern Ireland, and if you
          are resident of Scotland, you may also bring proceedings in Scotland.
          If you have any complaint or wish to raise a dispute under these Terms
          and Conditions or otherwise in relation to the Site please follow this
          link http://ec.europa.eu/odr
        </p>
        <p>
          10.9 A person who is not a party to these Terms and Conditions shall
          have no right under the Contracts (Rights of Third Parties) Act 1999
          to enforce any term of these Terms and Conditions.
        </p>
        <p>
          10.10 In order to resolve a complaint regarding the Services or to
          receive further information regarding use of the Services, please
          contact us by email at karoulla.andrico@gmail.com or by post to:
        </p>
        <p>
          Andrico Karoulla
          <br />
          317 Brockley Road
          <br />
          SE4 2QZ
          <br />
          London
          <br />
          United Kingdom
        </p>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;
